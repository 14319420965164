<template>
    <div class="default-site-layout">
        <DelayHydration>
            <AppHeader />
        </DelayHydration>

        <div class="default-site-layout__header-and-outlet-separator"></div>

        <div class="default-site-layout__nuxt-outlet">
            <slot />
        </div>

        <MiscScrollTop />

        <DelayHydration>
            <AppFooter />
        </DelayHydration>

        <Teleport to="body">
            <LazyMiscModalPosition
                v-if="AuthStore.AuthModal"
                :close-handler="() => AuthStore.setAuthModal(null)"
            >
                <LazyAuthDialogSignIn v-if="AuthStore.AuthModal.type === 'sign-in'" />
                <LazyAuthDialogSignUp v-if="AuthStore.AuthModal.type === 'sign-up'" />
                <LazyAuthDialogForgotPassword v-if="AuthStore.AuthModal.type === 'forgot-password'" />
                <LazyAuthDialogResetPasswordInit v-if="AuthStore.AuthModal.type === 'reset-password-init'" />
                <LazyAuthDialogEmailVerification
                    v-if="AuthStore.AuthModal.type === 'email-verification'"
                    :info="AuthStore.AuthModal.info"
                />
                <LazyAuthDialogEmailLoginStrategy v-if="AuthStore.AuthModal.type === 'email-sign-in'" />
                <LazyAuthDialogResetPasswordInit v-if="AuthStore.AuthModal.type === 'reset-password'" />
                <LazyAuthDialogAcceptTerms
                    v-if="AuthStore.AuthModal.type === 'accept-terms'"
                    :info="AuthStore.AuthModal.info"
                />
            </LazyMiscModalPosition>
            <LazyMiscModalPosition
                :close-handler="() => RootStore.setCouponModal(null)"
                v-if="RootStore.couponModal"
            >
                <LazyDWidgetModal v-if="RootStore.couponModal" :info="RootStore.couponModal" />
            </LazyMiscModalPosition>
            <LazyMiscModalPosition
                v-if="RootStore.genericModal"
                :close-handler="() => RootStore.setGenericModal(null)"
            >
                <LazyDialogConfirm
                    v-if="RootStore.genericModal.type === 'confirm'"
                    :info="RootStore.genericModal.info"
                />
                <LazyDialogNewMessage
                    v-if="RootStore.genericModal.type === 'message'"
                    :info="RootStore.genericModal.info"
                />
            </LazyMiscModalPosition>
            <LazyMiscModalPosition
                v-if="RootStore.dashboardModal"
                :close-handler="() => RootStore.setDashboardModal(null)"
            >
                <LazyDashDialogEmail
                    v-if="RootStore.dashboardModal.type === 'email'"
                    :info="RootStore.dashboardModal.info"
                />
                <LazyDashDialogPassword
                    v-if="RootStore.dashboardModal.type === 'password'"
                    :info="RootStore.dashboardModal.info"
                />
                <LazyDashDialogUsername
                    v-if="RootStore.dashboardModal.type === 'username'"
                    :info="RootStore.dashboardModal.info"
                />
            </LazyMiscModalPosition>
            <LazyMiscMobileDropdown />
        </Teleport>
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

await preloadComponents('AppHeader')

const RootStore = useRootStore()

const AuthStore = useAuthStore()

const adbarsDesktop = RootStore.layoutData?.data.header.adbar?.desktop

const adbarsMobile = RootStore.layoutData?.data.header.adbar?.mobile

useHead({
    bodyAttrs: {
        class:
            (RootStore.layoutData?.data.header.adbar ? 'add-adbars-height' : '') +
            (adbarsDesktop ? ' show-desktop-adbars' : '') +
            (adbarsMobile ? ' show-mobile-adbars' : ''),
    },
})
const app = useNuxtApp()

const config = useRuntimeConfig()

console.log(config.public.env)

app.hook('page:finish', () => {
    document.documentElement.scrollTop = 0
})
</script>

<style lang="postcss">
.default-site-layout {
    @apply relative flex flex-col justify-between;

    --header-menu-height: theme('spacing.14');
    --headband-height: theme('spacing.8');
    --total-header-height: calc(
        var(--notification-height, 0rem) + var(--layout-header-adbar-height, 0rem) +
            var(--header-menu-height, 0rem) + var(--headband-height, 0rem)
    );

    @apply min-h-screen overflow-x-hidden bg-site-background;

    &__nuxt-outlet {
        @apply relative flex flex-grow flex-col transition-[margin-top] duration-300;

        min-height: calc(100vh - var(--total-header-height));

        > * {
            @apply flex-grow;
        }
    }

    &__header-and-outlet-separator {
        @apply block h-0 w-full transition-[margin-top] duration-300;

        margin-top: var(--total-header-height, 0rem);
    }
}

@screen lg {
    .default-site-layout {
        --header-menu-height: theme('spacing.16');
    }
}
</style>
